import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Loader2 } from 'lucide-react';

const MemeGenerator = () => {
  const [prompt, setPrompt] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const generateMeme = async () => {
    setLoading(true);
    setError('');
    
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: 'Bearer key-xehKfsWxL4xzMZv14gOMbHCmI3jmA6AvAtAe5IyPCrV0JsTz9xFabVOBRQ1zaPzZ4I4BWEYCqJuTtIXkXZ84Xdel2129LCN'
      },
      body: JSON.stringify({ prompt })
    };

    try {
      const response = await fetch('https://api.getimg.ai/v1/flux-schnell/text-to-image', options);
      const data = await response.json();
      
      if (data.image) {
        setImageUrl(`data:image/png;base64,${data.image}`);
      } else {
        setError('Failed to generate image');
      }
    } catch (err) {
      setError('Error generating meme: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'meme.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>AI Meme Generator</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex space-x-4">
          <Input
            placeholder="Enter your meme prompt..."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            className="flex-1"
          />
          <Button 
            onClick={generateMeme}
            disabled={loading || !prompt.trim()}
          >
            {loading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Generating...
              </>
            ) : (
              'Generate'
            )}
          </Button>
        </div>

        {error && (
          <div className="text-red-500 text-sm">{error}</div>
        )}

        {imageUrl && (
          <div className="space-y-4">
            <img 
              src={imageUrl} 
              alt="Generated meme"
              className="w-full rounded-lg shadow-lg"
            />
            <Button 
              onClick={downloadImage}
              className="w-full"
            >
              Download Meme
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default MemeGenerator;