import logo from './assets/logo-tp.png';
import './App.css';
import MemeGenerator from './components/MemeGenerator';

function App() {
  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <img src={logo} alt="logo" className="w-16 h-16 mb-4" />
      <h1 className="text-2xl font-bold mb-4">Meme Generator</h1>
    <MemeGenerator />
  </div>
  );
}

export default App;
